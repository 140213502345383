import { axios } from '@/utils/request'

//post
function post(url, parameter) {
    return axios({
        url: url,
        method: 'post',
        data: parameter
    })
}

//post method= {post | put}
function http(url, parameter, method) {
    return axios({
        url: url,
        method: method,
        data: parameter
    })
}

//put
function put(url, parameter) {
    return axios({
        url: url,
        method: 'put',
        data: parameter
    })
}

//get
function get(url, parameter) {
    return axios({
        url: url,
        method: 'get',
        params: parameter
    })
}

//delete
function _delete(url, parameter) {
    return axios({
        url: url,
        method: 'delete',
        params: parameter
    })
}


/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
function downFile(url, parameter) {
    return axios({
        url: url,
        params: parameter,
        method: 'get',
        responseType: 'blob'
    })
}

export default {
    post,
    http,
    get,
    _delete,
    put,

}