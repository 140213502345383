<template>
  <div>
    <video
      ref="videoPlayer"
      class="video-js vjs-default-skin vjs-big-play-centered"
      controls
      style="object-fit: fill"
      disablePictureInPicture
      preload="auto"
      x5-playsinline="true"
      x-webkit-airplay="true"
      x5-video-player-type="h5"
      x5-video-player-fullscreen="true"
      x5-video-ignore-metadata="true"
      x5-video-orientation="landscape"
    ></video>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {},
  methods: {
    init() {
      let _this = this;
      this.player = this.$video(
        this.$refs.videoPlayer,
        this.options,
        function onPlayerReady() {
          console.log("onPlayerReady", this);
          setTimeout(() => {
            //   _this.player.muted = false;
            // this.volume(100);
            // this.volume = 100;
            // this.muted=false;
          }, 500);
        }
      );
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>