<template>
  <div>
    <video-player ref="player" :options="videoOptions" />

    <div class="title">直播简介：</div>
    <div class="showContent">{{ video_obj.showContent }}</div>
  </div>
</template>
<script>
import VideoPlayer from "../components/video/index.vue";

export default {
  components: {
    VideoPlayer,
  },
  mounted() {
    let id = JSON.parse(decodeURIComponent(this.getQueryString("id")));
    this.http
      .get(`schoolShow/getById`, {
        id: id,
      })
      .then((res) => {
        this.video_obj = res;
        // console.log(this.deviceType());
        console.log("系统名：");
        console.log(this.deviceType());
        if (this.deviceType() == "isAndroid" || this.deviceType() == "isIOS") {
          this.videoOptions.sources[0].src = res.url.replace(/https/, "http");
        } else {
          this.videoOptions.sources[0].src = res.url;
        //   this.videoOptions.sources[0].src = res.url.replace(/https/, "http");
        }
        console.log("实际的直播地址:");
        console.log(this.videoOptions.sources[0].src);
        this.$refs.player.init();
        // this.$refs.player.player.volume(100) ;
      });
  },
  methods: {
    // 判断设备是否是ios
    deviceType() {
      var u = navigator.userAgent,
        app = navigator.appVersion;
      // console.log('Windows') ;
      // console.log(app) ;
      // console.log(u);
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      var Windows = u.indexOf("Windows") > -1 || u.indexOf("Linux") > -1; //g
      //   console.log("isAndroid" + isAndroid);
      //   console.log("isIOS" + isIOS);
      if (isAndroid) {
        return "isAndroid";
      }
      if (isIOS) {
        return "isIOS";
      }
      if (Windows) {
        return "Windows";
      }
    },

    // 解析URL
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return decodeURI(r[2]);
      return null;
    },
  },
  data() {
    return {
      video_obj: {},

      videoOptions: {
        autoplay: "muted", //自动播放
        controls: true, //用户可以与之交互的控件
        loop: true, //视频一结束就重新开始
        muted: false, //默认情况下将使所有音频静音
        aspectRatio: "16:9", //显示比率
        fullscreen: {
          options: { navigationUI: "hide" },
        },
        sources: [
          {
            src: "",
            type: "application/x-mpegURL",
          },
        ],
      },
    };
  },
};
</script>
<style scoped>
.title {
  margin: 10px;
  font-size: 20px;
}
.showContent {
  margin: 10px;
  font-size: 18px;
}
</style>
