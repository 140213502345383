import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false


// 引入videojs
// src/main.js => 主入口文件


// 引入videojs
// 在main.js中全局引入
import Video from 'video.js'
import 'video.js/dist/video-js.css'

import hls from 'videojs-contrib-hls';
Vue.use(hls);


Vue.prototype.$video = Video


import http from './api/manage.js';
Vue.prototype.http = http;





new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
